const datas = [
  {
    src: 'oasis.png',
    alt: 'empresa',
    facebook: 'https://www.facebook.com',
    facebookImage: 'facebook.png',
    instagram: 'https://www.instagram.com',
    instagramImage: 'instagram.png',
  },
  {
    src: 'oasis.png',
    alt: 'empresa',
    facebook: 'https://www.facebook.com',
    facebookImage: 'facebook.png',
    instagram: 'https://www.instagram.com',
    instagramImage: 'instagram.png',
  },
  {
    src: 'oasis.png',
    alt: 'empresa',
    facebook: 'https://www.facebook.com',
    facebookImage: 'facebook.png',
    instagram: 'https://www.instagram.com',
    instagramImage: 'instagram.png',
  },
  {
    src: 'oasis.png',
    alt: 'empresa',
    facebook: 'https://www.facebook.com',
    facebookImage: 'facebook.png',
    instagram: 'https://www.instagram.com',
    instagramImage: 'instagram.png',
  },
  {
    src: 'oasis.png',
    alt: 'empresa',
    facebook: 'https://www.facebook.com',
    facebookImage: 'facebook.png',
    instagram: 'https://www.instagram.com',
    instagramImage: 'instagram.png',
  },
  {
    src: 'oasis.png',
    alt: 'empresa',
    facebook: 'https://www.facebook.com',
    facebookImage: 'facebook.png',
    instagram: 'https://www.instagram.com',
    instagramImage: 'instagram.png',
  },
  {
    src: 'oasis.png',
    alt: 'empresa',
    facebook: 'https://www.facebook.com',
    facebookImage: 'facebook.png',
    instagram: 'https://www.instagram.com',
    instagramImage: 'instagram.png',
  },
  {
    src: 'oasis.png',
    alt: 'empresa',
    facebook: 'https://www.facebook.com',
    facebookImage: 'facebook.png',
    instagram: 'https://www.instagram.com',
    instagramImage: 'instagram.png',
  },
  {
    src: 'oasis.png',
    alt: 'empresa',
    facebook: 'https://www.facebook.com',
    facebookImage: 'facebook.png',
    instagram: 'https://www.instagram.com',
    instagramImage: 'instagram.png',
  },
  {
    src: 'oasis.png',
    alt: 'empresa',
    facebook: 'https://www.facebook.com',
    facebookImage: 'facebook.png',
    instagram: 'https://www.instagram.com',
    instagramImage: 'instagram.png',
  },
];

export default datas;
