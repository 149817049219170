import React from 'react';

import datas from './data';

import * as S from './styles';

const Companies = () => {
  return (
    <S.Container>
      <S.CompaniesWrapper>
        <S.CompaniesListWrapper>
          {datas.map(data => (
            <S.CompaniesList>
              <S.CompaniesListItem src={data.src} alt={data.alt} />
              <S.CompaniesSocialWrapper>
                <S.CompaniesFacebookLink href={data.facebook}>
                  <S.CompaniesFacebookImage
                    src={data.facebookImage}
                    alt="Facebook"
                  />
                </S.CompaniesFacebookLink>
                <S.CompaniesInstagramLink href={data.instagram}>
                  <S.CompaniesInstagramImage
                    src={data.instagramImage}
                    alt="Instagram"
                  />
                </S.CompaniesInstagramLink>
              </S.CompaniesSocialWrapper>
            </S.CompaniesList>
          ))}
        </S.CompaniesListWrapper>
      </S.CompaniesWrapper>
    </S.Container>
  );
};

export default Companies;
