import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  padding: 0 100px;

  ${media.lessThan('large')`
    padding: 0 50px;
  `};
`;

export const CompaniesWrapper = styled.div``;

export const CompaniesListWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${media.lessThan('large')`
    justify-content: space-between;
  `};
`;

export const CompaniesList = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-right: 50px;
  padding-top: 40px;
  position: relative;

  ${media.lessThan('large')`
    margin-right: 0;
  `};

  &:before {
    content: '';
    position: absolute;
    width: 58%;
    height: 1px;
    bottom: -17px;
    background-color: #313843;
  }
`;

export const CompaniesListItem = styled.img`
  width: 200px;

  ${media.lessThan('large')`
    width: 100%;
  `};
`;

export const CompaniesSocialWrapper = styled.div`
  display: flex;
  padding-top: 20px;
`;

export const CompaniesFacebookImage = styled.img`
  width: 13px;
`;

export const CompaniesFacebookLink = styled.a`
  margin-right: 10px;
`;

export const CompaniesInstagramImage = styled.img`
  width: 24px;
`;

export const CompaniesInstagramLink = styled.a``;
