import React from 'react';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Companies from '../components/Companies';

const Empresa = () => {
  return (
    <>
      <SEO title="Empresas" />
      <Layout>
        <Companies />
      </Layout>
    </>
  );
};

export default Empresa;
